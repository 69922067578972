<template>
  <div class="home">
    <h1>The user has been deleted </h1>
  </div>
  <Redirect to=""></Redirect>
</template>

<script>
// import BackendModel from "../../models/BackendModel"
// import axios from 'axios'

import BackendModel from "@/models/BackendModel";

export default {
  name: 'BackofficeDeleteEnduser',
  components: {
  },
  async mounted() {
    this.id = this.$route.query.id
    this.point_id_prod = this.$route.query.id
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/delete_enduser", {"id": this.id})
    this.view_data = view_data.data.view_data
    this.loader_prop = false
    await this.$router.push('/backoffice/viewing_endusers_admin?id=' + this.$route.query.pid);
  }
}
</script>